<template>
  <div class="row" v-if="!$wait.waiting('getEventScoreById')">

    <div class="col px-0 px-lg-3">
      <div class="shadow rounded bg-white py-3 py-md-4 px-lg-2 mb-4">

        <div class="col d-flex flex-wrap align-items-center justify-content-between mb-3">
          <div>
            <i class="nav-icon icons8-stats h5 mr-2 mb-0"></i>
            <router-link :to="'/events/' + event.id">
              <span class="h5 mr-3"> {{ event.name }}</span>
            </router-link>
          </div>
          <button @click="exportToExcel" class="btn btn-success" type="button">
            <i class="icons8-download"></i>
            Экспорт в Excel
          </button>
        </div>

        <div class="col mb-3">
          <span>Дата: <strong>{{ event.opens_from }}</strong></span>
        </div>

        <div class="col mb-3">
          <v-client-table :columns="columns"
                          :options="tableOptions"
                          :data="eventHistory">
            <router-link slot="userFullName"
                         slot-scope="props"
                         :to="userDetailLink( props.row.user.data.id )">
              {{ props.row.userFullName }}
            </router-link>
          </v-client-table>
        </div>

      </div>
    </div>

  </div>
</template>

<script>
  import pick from 'lodash/pick'
  import XLSX from 'xlsx'

  export default {
    name: 'ScoreEventDetail',

    metaInfo() {
      return {
        title: this.title,
      }
    },

    data() {
      return {
        title: 'Статистика',
        event: {},
        eventHistory: [],
        columnsSettings:
          [
            {
              name: 'Посетитель',
              alias: 'userFullName',
              sortable: true,
              filterable: true,
              visible: true,
            },
            {
              name: 'Магазин',
              alias: 'userShop',
              sortable: true,
              filterable: true,
              visible: true,
            },
            {
              name: 'Во сколько',
              alias: 'created_at',
              sortable: true,
              filterable: false,
              visible: true,
            },
          ],
      }
    },

    computed: {
      tableOptions() {
        return {
          filterByColumn: true,
          skin: 'table table-bordered table-striped',
          filterable: this.filterableColumns,
          sortable: this.sortableColumns,
          headings: this.headings,
          perPage: 500,
          perPageValues: [500],
          pagination: { chunk: this.$store.getters.windowSize === 'large' ? 10 : 3 },
          columnsDropdown: false,
          orderBy: {
            column: 'created_at',
            ascending: false,
          },
          columnsClasses: {
            id: 'mw-100',
          },
          sortIcon: {
            base: 'icons8',
            up: 'icons8-chevron-up',
            down: 'icons8-chevron-down',
            is: 'icons8-up-and-down-arrows',
          },
        }
      },

      filterableColumns() {
        return this.columnsSettings.filter( column => column.filterable ).map( column => column.alias )
      },

      sortableColumns() {
        return this.columnsSettings.filter( column => column.sortable ).map( column => column.alias )
      },

      headings() {
        let result = {}

        this.columnsSettings.forEach( column => {
          result[column.alias] = column.name
        } )

        return result
      },

      columns() {
        return this.columnsSettings.filter( column => column.visible ).map( column => column.alias )
      },
    },

    created() {
      this.$wait.start( 'getEventScoreById' )

      this.$api.event.getEventScoreById( this.$route.params.event_id ).then( res => {
        if ( res.status === 200 && res.data.data ) {
          let event = res.data.data
          let eventHistory = event.event_history.data

          eventHistory.forEach( history => {
            let user = history.user.data
            let fullName = `${user.surname || ''} ${user.name} ${user.patronymic || ''}`
            let shopIdentifier = user.shop.data ? user.shop.data.code || user.shop.data.name : ''

            this.$set( history, 'userFullName', fullName.trim() )
            this.$set( history, 'userShop', shopIdentifier )
          } )

          this.event = event
          this.eventHistory = eventHistory
          this.title = 'Статистика ' + event.name || ''
        }
      } ).finally( () => {
        this.$wait.end( 'getEventScoreById' )
      } )

      this.$root.$on( 'EVENT_HISTORY_CREATED', message => {
        if ( message && message.data ) {
          let newEventHistory = message.data.eventHistory.data

          if ( newEventHistory && newEventHistory.event.data.id === this.event.id ) {
            let newUser = newEventHistory.user.data
            let shopIdentifier = newUser.shop.data ? newUser.shop.data.code || user.shop.data.name : ''
            let fullName = `${newUser.surname || ''} ${newUser.name}  ${newUser.patronymic || ''}`

            newEventHistory.userFullName = fullName.trim()
            newEventHistory.userShop = shopIdentifier

            this.eventHistory.push( newEventHistory )
          }
        }
      } )
    },

    beforeRouteEnter( to, from, next ) {
      if ( to.params.event_id.length !== parseInt( process.env.VUE_APP_HASH_ID_LENGTH ) ) {
        next( vm => {
          vm.$router.push( { name: 'NotFound' } )
        } )
      } else {
        return next()
      }
    },

    methods: {
      userDetailLink( id ) {
        return `/users/${id}`
      },

      exportToExcel() {
        const now = new Date().toLocaleDateString( 'ru', {
          timezone: 'Europe/Moscow',
          hour: 'numeric',
          minute: 'numeric',
          second: 'numeric',
        } )

        let formatted = this.eventHistory.map( item => {
          return pick( item, ['userFullName', 'userShop', 'created_at'] )
        } )

        let scoreWS = XLSX.utils.json_to_sheet( formatted, {
          header: ['userFullName', 'userShop', 'created_at'],
          skipHeader: false,
        } )

        scoreWS.A1.v = 'Посетитель'
        scoreWS.B1.v = 'Магазин'
        scoreWS.C1.v = 'Во сколько'

        scoreWS['!cols'] = [
          { wch: 40 },
          { wch: 20 },
          { wch: 10 },
        ]

        let workbook = XLSX.utils.book_new()

        XLSX.utils.book_append_sheet( workbook, scoreWS, 'score' )
        XLSX.writeFile( workbook, `score_${this.event.name}_${now}.xlsx` )
      },
    },
  }
</script>
